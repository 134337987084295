@import url(//fonts.googleapis.com/css?family=Assistant:200,300,400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@font-face {
    font-family: "keystone";
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
    src: url(https://whatsontv.snlb.pl/static/media/standard.457004f3.woff2) format("woff2"),
    url(https://whatsontv.snlb.pl/static/media/standard.39b0c7e6.woff) format("woff");
}

body {
    margin: 0;
    font-family: "Assistant", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@-webkit-keyframes fadeIn {
    99% {
        visibility: hidden;
        opacity: 0;
    }
    100% {
        visibility: visible;
        opacity: 1;
    }
}

@keyframes fadeIn {
    99% {
        visibility: hidden;
        opacity: 0;
    }
    100% {
        visibility: visible;
        opacity: 1;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.providers-row {
    background-color: #fff;
    border: 1px solid #A8AFB8;
    border-bottom: 0;
}

.providers-row > .col {
    padding: 0;
}

.providers-row > .col > nav {
    padding: 10px;
}

@media (max-width: 768px) {
    .providers-row {
        border: 1px solid #EAEEF3;
    }

    .providers-row > .col:first-child {
        /*border-right: 1px solid #EAEEF3;*/
    }

    .providers-row > .col > nav {
        padding: 2px;
    }
}

#providers-navbar-nav .nav-pills {
    justify-content: space-between;
    flex-grow: 1;
}

#providers-navbar-nav .nav-pills .nav-link {
    flex-basis: auto;
    font-weight: bold;
    color: #7D8083;
    font-size: 15px;
}

#providers-navbar-nav .nav-pills .nav-link:hover {
    color: #000;
}

#providers-navbar-nav .nav-pills .nav-link.active {
    text-align: center;
    background-color: #4D555F;
    color: #ffffff;
    border-radius: 100px;
    padding-left: 22px;
    padding-right: 22px;
}

#providers-navbar-nav .nav-pills .show > .nav-link {
    background-color: #e8e8e8;
}

#providers-navbar-nav .dropdown-item:active {
    background-color: #fff;
}

@media (max-width: 768px) {
    #providers-navbar-nav .nav-pills .nav-link.active,
    #providers-navbar-nav .nav-pills .nav-link {
        padding-left: 20px;
        padding-right: 20px;
        font-size: 14px;
        text-align: center;
    }
}

.guide-settings-btn {
    display: flex;
    justify-content: right;
    align-items: center;
    color: #909194;
    font-weight: bold;
}

.guide-settings-btn > div {
    cursor: pointer;
    padding: 10px 20px;
    font-size: 14px;
}

.guide-settings-btn svg {
    font-size: 20px;
}

#settings-bar {
    padding: 20px;
    border-left: 1px solid #EAEEF3;
    border-right: 1px solid #EAEEF3;
}

#settings-bar h4 {
    color: #353A40;
    font-size: 36px;
    font-weight: bold;
}

#settings-bar .form-control {
    border-radius: 0;
    border-color: #A8AFB8;
    color: #353A40;
    font-size: 16px;
}

#settings-bar nav {
    padding: 0;
}

#genres-navbar-nav .nav-item {
    position: relative;
    margin: 0 3px 0 0;
}

#genres-navbar-nav .nav-item .color-bar {
    position: absolute;
    height: 5px;
    bottom: 0;
    width: 30px;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
}

#genres-navbar-nav .nav-pills .nav-link {
    padding: 8px 20px;
    font-weight: bold;
    font-size: 16px;
    color: #7D8083;
}

#genres-navbar-nav .nav-pills .nav-link.active {
    background-color: #4D555F;
    color: #ffffff;
    border-radius: 100px;
    padding: 8px 22px;
}

#regionSelectWrapper {
    margin-bottom: 0;
    width: 100%;
    padding: 8px;
}

@media (max-width: 768px) {
    #settings-bar h4 {
        font-size: 26px;
    }

    .guide-settings-btn > div {
        font-size: 12px;
    }

    .guide-settings-btn svg {
        font-size: 16px;
    }

    #settings-bar {
        padding: 20px 5px;
    }

    #settings-bar nav {
        display: block;
    }

    #settings-bar nav .navbar-nav {
        display: block;
        margin-left: -10px;
        width: calc(100% + 20px);
    }

    #genres-navbar-nav.navbar-expand .navbar-nav {
        flex-direction: unset;
        display: block;
        text-align: center;
    }

    #genres-navbar-nav .nav-item {
        display: inline-block;
        margin: 0 5px 10px 0;
    }

    #genres-navbar-nav .nav-pills .nav-link {
        min-width: 60px;
        padding: 5px 10px;
    }

    .img-fluid {
        margin-bottom: 15px;
    }
}

.datepicker-bar {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    height: 4rem;
    background-color: #353A40;
    color: #ffffff;
    overflow: hidden;
    z-index: 15;
}

.datepicker-bar .arrow-button {
    width: 44px;
    background-color: #212529;
    height: 100%;
    font-size: 30px;
    line-height: 58px;
    position: absolute;
    top: 0;
    z-index: 5;
    text-align: center;
    cursor: pointer;
}

.datepicker-bar .arrow-button.arrow-left {
    left: 0;
}

.datepicker-bar .arrow-button.arrow-right {
    right: 0;
}

.datepicker-bar ul.datepicker-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    -webkit-transition: all 0.6s cubic-bezier(0.65, 0.05, 0.36, 1) 0s;
    transition: all 0.6s cubic-bezier(0.65, 0.05, 0.36, 1) 0s;
}

.datepicker-bar li {
    display: inline;
    white-space: nowrap;
    overflow: hidden;
}

.datepicker-bar li a,
.datepicker-bar li a:hover,
.datepicker-bar li a:visited,
.datepicker-bar li a:active {
    padding: 10px 25px;
    font-weight: bold;
    color: #ffffff;
    text-decoration: none;
    font-size: 16px;
}

.datepicker-bar li a:hover {
    color: #e2e2e2;
}


.datepicker-bar li a.selected {
    background-color: #4D555F;
    border-radius: 100px;
}

@media (max-width: 768px) {
    .datepicker-bar {
        height: 44px;
    }

    .datepicker-bar .arrow-button {
        line-height: 42px;
        font-size: 26px;
    }

    .datepicker-bar li a,
    .datepicker-bar li a:hover,
    .datepicker-bar li a:visited,
    .datepicker-bar li a:active {
        font-size: 12px;
        padding: 5px 15px;
    }
}

.timepicker-bar {
    position: -webkit-sticky;
    position: sticky;
    top: 64px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    height: 3rem;
    background-color: #ccd4df;
    color: #838284;
    overflow: hidden;
    z-index: 15;
}

.timepicker-bar .arrow-button {
    width: 100px;
    background-color: #ccd4df;
    height: 100%;
    font-size: 35px;
    line-height: 31px;
    padding: 7px 33px;
    position: absolute;
    top: 0;
    z-index: 5;
}

.timepicker-bar .arrow-button.arrow-left {
    left: 0;
    border-right: 2px solid white;
    padding: 7px 25px;
}

.timepicker-bar .arrow-button.arrow-right {
    right: 0;
    border-left: 2px solid white;
}

.timepicker-bar ul.timepicker-list {
    list-style-type: none;
    display: flex;
    flex-wrap: nowrap;
    justify-content: stretch;
    align-items: stretch;
    padding: 0;
    margin: 0 0 0 82px;
    width: calc(100% - 164px);
    -webkit-transition: all 0.6s cubic-bezier(0.65, 0.05, 0.36, 1) 0s;
    transition: all 0.6s cubic-bezier(0.65, 0.05, 0.36, 1) 0s;
}

.timepicker-bar li {
    flex: 1 1;
    display: block;
    white-space: nowrap;
    overflow: hidden;
}

.timepicker-bar li a {
    display: block;
    width: 100%;
    padding: 15px;
    font-weight: bold;
    border-right: 2px solid white;
    font-size: 16px;
    color: #7D8083;
}

@media (max-width: 768px) {
    .timepicker-bar {
        height: 44px;
        background-color: #fff;
        border-bottom: 1px solid #EAEEF3;
        top: 44px;
    }

    .timepicker-bar .arrow-button {
        width: 44px;
        padding: 0 !important;
        text-align: center;
        line-height: 36px;
        background-color: #fff;
        font-size: 26px;
    }

    .timepicker-bar ul.timepicker-list {
        margin: 0 0 0 29px;
        width: calc(100% - 57px);
    }

    .timepicker-bar li a {
        color: #7D8083;
        font-size: 12px;
    }
}

.channel-loader {
    text-align: center;
    margin-top: 20px;
}

.channel-row {
    height: 56px;
    margin-left: 0px;
}

.channel-header {
    z-index: 10;
    width: 99px;
    border-right: 1px solid #BCBCBC;
    border-bottom: 1px solid #BCBCBC;
    align-items: center;
    display: flex;
}

.channel-header img {
    width: 100%;
}

.channel-schedule {
    position: relative;
    flex-grow: 1;
    background-color: #efefef;
    border-bottom: 1px solid #c9cac9;
}

.channel-no-data {
    background-color: #efefef;
    text-align: center;
    color: #b1b1b1;
    line-height: 50px;
    border-bottom: 1px solid #c9cac9;
}

.channel-no-data span {
    -webkit-animation: 600ms fadeIn;
            animation: 600ms fadeIn;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    visibility: hidden;
}

.channel-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-left: 1px solid #c9cac9;
    border-right: 1px solid #c9cac9;
}

@media (max-width: 768px) {
    .channel-header {
        width: 44px;
        border-right: 1px solid #EAEEF3;
        border-bottom: 1px solid #EAEEF3;
    }

    .channel-schedule {
        border-bottom: 1px solid #EAEEF3;
    }
}

.program-box {
    border-right: 1px solid #BCBCBC;
    position: absolute;
    top: 0;
    height: 100%;
    background: #fff;
    padding: 7px 11px 7px;
}

.program-box.selected {
    background-color: #EAEEF3;
}

.program-box.highlighted {
    background: repeating-linear-gradient(-55deg, rgb(239, 241, 245), rgb(239, 241, 245) 10px, rgb(246, 248, 252) 10px, rgb(246, 248, 252) 20px);
}

.program-box h4 {
    color: #353A40;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 1px;
    font-weight: bold;
}

.program-box h5 {
    color: #353A40;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.program-box-category-bar {
    position: absolute;
    height: 5px;
    width: 100%;
    bottom: -1px;
    left: 0;
}

@media (max-width: 768px) {
    .program-box {
        border-right: 1px solid #EAEEF3;
    }

    .program-box h4 {
        font-size: 14px;
    }

    .program-box h5 {
        font-size: 10px;
    }
}

.program-details {
    z-index: 2;
    position: relative;
    background-color: #EAEEF3;
    border-bottom: 1px solid #c9cac9;
    padding: 40px;
}

.program-details .program-details-close {
    position: absolute;
    right: 10px;
    top: 6px;
    z-index: 10;
    cursor: pointer;
    color: #7D8083;
    font-size: 16px;
}

.program-details .program-details-datetime {
    font-size: 16px;
    color: #353A40;
    margin-bottom: 30px;
}

.program-details .title {
    font-size: 22px;
    color: #353A40;
}

.program-details .title a {
    text-decoration: underline;
    color: #353A40;
}

.program-details .title a:hover {
    color: #df0100;
}

.program-details .subTitle {
    font-size: 16px;
    color: #7D8083;
}

.program-details .description {
    font-size: 16px;
    color: #353A40;
}

@media (max-width: 768px) {
    .program-details {
        padding: 15px;
    }

    .program-details .program-details-datetime {
        font-size: 11px;
        margin-bottom: 15px;
    }

    .program-details .title {
        margin-bottom: 0;
        font-size: 16px;
    }

    .program-details .subTitle {
        font-size: 12px;
        margin-bottom: 15px;
    }

    .program-details .description {
        font-size: 14px
    }
}

.scheduler-search-form {
}

.scheduler-search-form .input-group-prepend .input-group-text {
    background-color: #353A40;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
}

.scheduler-search-form .input-group-append .input-group-text {
    background-color: white;
}

.scheduler-search-form .input-group-append .input-group-text:hover {
    background-color: #f7f7f7;
}

@media (max-width: 768px) {
    .scheduler-search-form .input-group-prepend .input-group-text {
        font-size: 14px;
    }

    .scheduler-search-form .input-group-append .input-group-text,
    .scheduler-search-form .form-control {
        border-color: #EAEEF3;
        color: #A8AFB8;
    }
}

.search-result-list {
    width: 100%;
    height: 500px;
    overflow: auto;
    -ms-scroll-chaining: none;
        overscroll-behavior: contain;
}

.search-result-list-item img {
    width: 108px;
    height: 64px;
}

.search-result-list-item .media-body {
    overflow: hidden;
    white-space: normal;
}

.search-result-list-item .media-body p:first-child {
    margin-bottom: 0;
}

.search-result-list-item .media-body p:not(:first-child) {
    margin-bottom: 0;
    line-height: 18px;
}

.search-result-modal img {
    object-fit: none;
    object-position: center;
    width: 100%;
    max-height: 300px;
    margin-bottom: 1rem;
}

/* header menu */
#wrapper {
    overflow: unset
}

@media (max-width: 45.49em) {
    #wrapper {
        padding-top: 61px !important
    }

    #wrapper .header {
        margin-top: -61px
    }
}

.header {
    z-index: 99;
    margin: 0 auto;
    overflow: visible;
    position: relative;
    line-height: 52px
}

.header ol, .header ul {
    list-style: none;
    padding: 0;
}

.header nav .menu-icon-link, .header nav .search-site {
    padding: 0 15px
}

.header nav .menu-icon-link span, .header nav .search-site span {
    height: 100%
}

.header nav a {
    display: block
}

.header .search-form, .header nav ul {
    position: absolute;
    overflow: hidden
}

.header .logo a {
    width: 110px
}

@media (min-width: 45.5em) {
    .header .logo a {
        width: 300px
    }
}

@media (min-width: 62em) {
    .header .logo a {
        width: 500px
    }
}

.header .logo a img {
    display: block;
    width: 100%
}

@media (min-width: 62em) {
    .header .logo a img {
        max-width: 500px
    }
}

.header .submenu-toggle {
    display: none
}

@media (max-width: 45.49em) {
    .header {
        /*position: fixed;*/
        padding: 5px 0;
        line-height: 50px;
        box-sizing: content-box;
        width: 100%;
        height: 52px
    }

    .header .header__inner {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%
    }

    .header .header-slot:not(.logo) {
        display: none
    }

    .header nav {
        width: 100%
    }

    .header nav .menu-icon-link, .header nav .search-site {
        margin-top: -50px
    }

    .header nav .menu-icon-link {
        float: left
    }

    .header nav .search-site {
        float: right
    }

    .header .navigation {
        top: 100%;
        width: 100%;
        height: 0
    }

    .header .navigation ul {
        position: static;
        position: initial
    }

    .header .navigation li {
        position: relative
    }

    .header .navigation li a {
        padding: 0 15px
    }

    .header .navigation li .submenu-toggle {
        display: block;
        font-size: .8em;
        position: absolute;
        right: 0;
        top: 0;
        text-align: center;
        width: 50px
    }

    .header .navigation li .submenu-toggle:before {
        display: inline-block
    }
}

@media (min-width: 45.5em) {
    .header {
        display: flex;
        align-items: center
    }

    .header .header__inner {
        -moz-flex-grow: 0;
        flex-grow: 0
    }

    .header nav {
        position: static;
        position: initial;
        -moz-flex-grow: 1;
        flex-grow: 1
    }

    .header nav ul {
        box-sizing: border-box;
        position: static;
        position: initial;
        overflow: visible
    }

    .header nav ul ul {
        display: none;
        position: absolute;
        padding: 0 45px
    }

    .header nav .navigation {
        display: inline-block
    }

    .header nav .navigation > li > a {
        padding: 10px
    }

    .header nav .navigation > li > ul {
        left: 0;
        padding-bottom: 30px;
        width: 100%;
        line-height: normal;
        line-height: initial;
        z-index: 9999999
    }

    .header nav .navigation > li > ul a {
        line-height: 1.3em;
        vertical-align: text-top
    }

    .header nav .navigation > li > ul > li {
        width: 200px;
        vertical-align: text-top;
        padding: 30px 15px 0
    }

    .header nav li {
        display: inline-block
    }

    .header nav .search-site {
        position: relative;
        float: right;
        right: -15px
    }

    .header nav .menu-icon-link {
        display: none
    }

    .header.has-double-tier {
        flex-wrap: wrap
    }

    .header.has-double-tier .nav-follow, .header.has-double-tier .nav-subscribe {
        display: none !important
    }

    .header.has-double-tier .header__inner {
        line-height: normal;
        line-height: initial;
        flex-basis: 100%;
        display: flex;
        align-items: center;
        padding-bottom: 15px;
        padding-top: 15px;
        margin: 0 0 -15px 0
    }

    .header.has-double-tier .header__inner .header-slot {
        flex-basis: 33.3%;
        order: 1
    }

    .header.has-double-tier .header__inner .header-slot-center {
        order: 2;
        text-align: center
    }

    .header.has-double-tier .header__inner .header-slot-center.logo {
        line-height: 0;
        padding: 0 0 15px 0
    }

    .header.has-double-tier .header__inner .header-slot-center.logo a {
        display: inline-block
    }

    .header.has-double-tier .header__inner .header-slot-right {
        order: 3
    }

    .header.has-double-tier .header-subscribe {
        display: flex;
        overflow: hidden;
        align-items: center
    }

    .header.has-double-tier .header-subscribe:after {
        content: "";
        display: table;
        clear: both
    }

    .header.has-double-tier .header-subscribe li {
        line-height: 20px
    }

    .header.has-double-tier .header-subscribe li a {
        color: #df0100;
        font-size: .8em;
        text-decoration: none
    }

    .header.has-double-tier .header-subscribe .icon-arrow-left, .header.has-double-tier .header-subscribe .icon-arrow-right {
        color: #df0100;
        font-size: .8em;
        padding: 0 5px
    }

    .header.has-double-tier .header-subscribe .magazine-cover {
        position: relative
    }

    .header.has-double-tier .header-subscribe img {
        max-width: 60px;
        position: relative;
        top: 0
    }

    .header.has-double-tier .header-subscribe.header-slot-left ul {
        float: left
    }

    .header.has-double-tier .header-subscribe.header-slot-left .icon-arrow-right {
        display: none
    }

    .header.has-double-tier .header-follow a {
        color: #df0100;
        display: inline-block;
        font-size: 1.8em;
        padding: 0;
        text-align: left
    }

    .header.has-double-tier .header-follow a:hover {
        color: #000;
    }

    .header.has-double-tier .header-follow a:before {
        float: left;
        text-indent: 0
    }

    .header.has-double-tier .header-follow ul {
        line-height: .7;
        padding: 0 0 0 15px
    }

    .header.has-double-tier .header-follow ul li {
        display: inline-block;
        line-height: .7;
        margin-left: 4px
    }

    .header.has-double-tier .header-follow ul li:first-child {
        margin-left: 0
    }

    .header.has-double-tier .header-follow.header-slot-right {
        text-align: right
    }

    .header.has-double-tier nav .navigation {
        padding-left: 35px
    }

    .header.has-double-tier nav .search-site {
        right: 30px
    }
}

@media (min-width: 62em) {
    .header.has-double-tier .header-subscribe .magazine-cover {
        overflow: hidden
    }

    .header.has-double-tier .header-subscribe .magazine-cover img {
        max-width: 100px
    }
}

@media (min-width: 90em) {
    .header nav .navigation > li > ul {
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }
}

@media (min-width: 1530px) {
    .header {
        padding-left: calc(50% - (1440px / 2));
        padding-right: calc(50% - (1440px / 2));
        max-width: none;
        box-sizing: border-box
    }

    .header .search-form, .header nav > ul > li > ul {
        padding-left: calc(50vw - (1440px / 2));
        padding-right: calc(50vw - (1440px / 2))
    }

    .header.has-double-tier {
        padding: 0
    }

    .header.has-double-tier .header__inner, .header.has-double-tier nav {
        padding-left: calc(50% - (1440px / 2));
        padding-right: calc(50% - (1440px / 2))
    }
}

.header {
    background: #fff;
    border-bottom: 3px #fff solid;
    border-top: none;
}

@media (max-width: 45.49em) {
    .header .logo img {
        max-height: 52px;
        max-width: 110px
    }

    .header nav .navigation {
        border-top: 3px #fff solid;
        border-top-color: transparent;
        background-clip: padding-box !important
    }
}

.header nav {
    background: #fff
}

.header nav .navigation {
    background: #fff
}

.header nav .navigation > li a {
    color: #000;
    font-size: 1.1em;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
}

.header nav .navigation > li > ul {
    background: #fbfbfb
}

.header nav .navigation > li > ul > li a {
    color: #000;
    font-size: .9em;
    font-weight: 700;
    text-transform: uppercase
}

.header .magazine-cover {
    height: auto;
}

.header .icon-search, .header .menu-icon-link {
    color: #df0100;
    outline: 0;
    text-decoration: none
}

.header .icon-search {
    color: #fff
}

@media (min-width: 45.5em) {
    .header.has-double-tier {
        border: none;
        padding: 0 20px
    }

    .header.has-double-tier nav {
        border-top: none;
        border-bottom: 3px #fff solid
    }

    .header .logo img {
        max-width: 300px
    }

    .header nav {
        text-align: center
    }

    .header nav .navigation {
        background: 0 0
    }

    .header nav .navigation > li a {
        position: relative
    }

    .header nav .navigation > li > a:after {
        background: #000;
        bottom: 0;
        content: "";
        height: 4px;
        left: 0;
        position: absolute;
        right: 100%
    }

    .header nav .navigation > li.menu-more {
        display: none;
        opacity: 0
    }

    .header nav .navigation > li.menu-more .icon-plus {
        font-size: .6em;
        line-height: .6em;
        position: relative;
        top: -2px
    }

    .header nav .navigation > li > ul {
        background-clip: padding-box !important;
        border-top: 3px #fff solid;
        border-top-color: transparent
    }

    .header nav .navigation > li > ul > li a {
        display: inline-block;
        font-size: 1em
    }
}

@media (min-width: 45.5em) and (max-width: 61.99em) {
    .header nav .navigation.hide-overflow-items > li:nth-child(n+5) {
        display: none
    }

    .header nav .navigation.hide-overflow-items > li:nth-child(n+5).menu-more {
        display: inline-block !important
    }
}

@media (min-width: 62em) and (max-width: 89.99em) {
    .header nav .navigation.hide-overflow-items > li:nth-child(n+7) {
        display: none
    }

    .header nav .navigation.hide-overflow-items > li:nth-child(n+7).menu-more {
        display: inline-block !important
    }
}

@media (min-width: 62em) {
    .header.has-double-tier .header-follow a {
        font-size: 2.2em
    }
}

@media (min-width: 768px) {
    #genres-navbar-nav > div {
        border-left: 1px solid #c9cac9;
        padding-left: 1em;
    }
}

.header.has-double-tier nav {
    border-top: 1px solid #eaeaea;
}

[class*=" icon-"]:after, [class*=" icon-"]:before, [class^=icon-]:after, [class^=icon-]:before {
    font-family: keystone !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    font-display: fallback;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-social-facebook--after:after, .icon-social-facebook:before {
    content: "f";
}

.icon-social-twitter--after:after, .icon-social-twitter:before {
    content: "t";
}

.icon-social-instagram--after:after, .icon-social-instagram:before {
    content: "i";
}

@media (min-width: 45.5em) {
    .header.has-double-tier .header-follow a:before {
        float: left;
        text-indent: 0;
    }
}

@media (min-width: 45.5em) {
    .header.has-double-tier .header-follow a:before {
        float: left;
        text-indent: 0;
        cursor: pointer;
    }
}


.search-form {
    background-color: #fff;
    border-bottom: 3px #fff solid;
    box-sizing: border-box;
    height: 0;
    left: 0;
    margin: 0;
    max-width: 100%;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 100%;
    width: 100vw;
    z-index: 9
}

.search-form .input-group {
    padding: 0 10px;
    position: relative;
    top: 72px;
    width: 100%;
    display: flex;
    flex-wrap: wrap
}

.search-form .input-group .btn {
    margin-left: 36px
}

.search-form .form-control {
    background: #fff;
    border: none;
    border-bottom: 3px #fff solid;
    border-radius: 0;
    box-shadow: none;
    color: #fff;
    display: inline-block;
    font-size: 2em;
    height: auto;
    padding: 10px 0;
    outline: 0;
    flex: 1 1
}

.search-form .form-control::-webkit-input-placeholder {
    color: #df0100
}

.search-form .form-control:-ms-input-placeholder {
    color: #df0100
}

.search-form .form-control::-ms-input-placeholder {
    color: #df0100
}

.search-form .message-wrapper {
    color: #df0100;
    float: left;
    font-size: .8em;
    margin-top: 10px;
    position: relative;
    width: 100%
}

.search-form .message-wrapper .message {
    left: 0;
    position: absolute;
    top: 0
}

.search-form .message-wrapper .message.hide {
    display: none
}

.hide {
    display: none !important;
}

.icon-arrow-left--after:after, .icon-arrow-left:before {
    content: "<";
}

@media (min-width: 45.5em) {

    .header nav .navigation > li:hover > .nav-title:after, .header nav .navigation > li:hover > a:after {
        right: 0;
    }

    .header nav .navigation > li > .nav-title:after, .header nav .navigation > li > a:after {
        background: #000;
        bottom: 0;
        content: "";
        height: 4px;
        left: 0;
        position: absolute;
        right: 100%;
        -webkit-transition: right .3s ease;
        transition: right .3s ease;
    }

    .header nav .navigation > li > a:after {
        background: #000;
        bottom: 0;
        content: "";
        height: 4px;
        left: 0;
        position: absolute;
        right: 100%;
    }
}

.header nav .navigation > li > ul > li a:hover {
    color: #df0100;
}

@media (min-width: 45.5em) {

    .header nav li:hover ul {
        display: block;
    }
}

/* new  */
.scheduler-header {
    padding: 0 58px;
    margin-bottom: 15px;
}

.scheduler-header h1 {
    font-size: 36px;
    color: #000;
    font-weight: bold;
    text-transform: uppercase;
}

.scheduler-header p {
    font-size: 18px;
    color: #353A40;
    font-weight: normal;
    margin: 0;
}

@media (max-width: 991px) {
    .scheduler-header {
        padding: 0 14px;
        margin-bottom: 0;
    }

    .scheduler-header h3 {
        font-size: 20px;
    }

    .scheduler-header p {
        margin-bottom: 10px;
        font-size: 14px;
    }
}

@media (min-width: 1556px) {
    .scheduler-header {
        max-width: 1440px;
    }
}

.scheduler-search-form .input-group-text {
    border-radius: 0;
}

.scheduler-container {
    margin: 0 58px 58px;
}

@media (max-width: 991px) {
    .scheduler-container {
        margin: 0;
    }

    .scheduler-search-form {
        /*margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px);*/
        margin-bottom: 15px;
    }
}

@media (min-width: 1556px) {
    .scheduler-container {
        max-width: 1440px;
        margin: auto;
    }
}

.buttonChannelSelect {
    font-weight: bold;
    color: #7D8083 !important;
    font-size: 14px;
    display: block;
    padding: 10px;
    border: 0;
    white-space: nowrap;
    outline: none !important;
}

.buttonChannelSelect > span {
    color: #353A40;
}

#current-time {
    position: absolute;
    top: 0;
    left: 200px;
    z-index: 1;
    width: 2px;
    height: 100%;
    background-color: #0fb8fb;
}

.current-time-arrow {
    left: 100px;
    bottom: 0;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #0fb8fb;
}

.ads-channel-container {
    position: relative;
    z-index: 2;
    background-color: #fff;
    border-bottom: 1px solid #BCBCBC;
    padding: 20px 0;
}

@media (max-width: 768px) {
    #genres-navbar-nav {
        padding: 10px;
        overflow: hidden;
    }
}

@media (max-width: 575px) {
    .program-details {
        padding-top: 40px;
    }
}

@media (min-width: 78.25em) {

    body.keystone-premium-inskin #wrapper,
    body.keystone-premium-sizemek #wrapper,
    body.keystone-premium-sizmek #wrapper,
    body.keystone-premium-skin #wrapper {
        margin-left: auto;
        margin-right: auto;
        max-width: 970px;
        width: calc(100vw - 260px) !important;
    }

    body.keystone-premium-inskin .scheduler-header,
    body.keystone-premium-sizemek .scheduler-header,
    body.keystone-premium-sizmek .scheduler-header,
    body.keystone-premium-skin .scheduler-header {
        padding: 0 58px;
        padding-bottom: 58px;
        background: white;
        margin-left: auto;
        margin-right: auto;
        max-width: 970px;
        width: calc(100vw - 260px) !important;
    }

    body.keystone-premium-inskin .scheduler-container,
    body.keystone-premium-sizemek .scheduler-container,
    body.keystone-premium-sizmek .scheduler-container,
    body.keystone-premium-skin .scheduler-container {
        margin-bottom: 58px;
        margin-left: auto;
        margin-right: auto;
        max-width: 970px;
        width: calc(100vw - 260px) !important;
    }

}

/* new menu */
:root {
    --flexi-brand_color_1: #000000;
    --flexi-brand_color_2: #2E9FDE;
    --flexi-brand_color_3: #000000;
    --flexi-secondary_color_1: #333333;
    --flexi-secondary_color_2: #2E9FDE;
    --flexi-secondary_color_3: #333333;
    --flexi-secondary_color_4: #0180b2;
    --flexi-secondary_color_5: #0180b2;
    --flexi-secondary_color_6: #000000;
}

.primary-nav {
    position: relative;
    z-index: 9997;
}

#burgerbar {
    background: #000000;
    background: var(--flexi-brand_color_1);
}

#burgerbar-none {
    display: none;
}

#burgerbar, #burgerbar ~ .burgerbar .button-menu.checked {
    display: none;
}

.burgerbar {
    position: relative;
}

.burgerbar {
    background-color: #000000;
    background-color: var(--flexi-brand_color_1);
    color: #fff;
    font-size: 24px;
    line-height: 60px;
    max-height: 60px;
    height: 60px;
}

@media only screen and (min-width: 700px) {
    .burgerbar {
        border-bottom: 1px solid #000000;
        border-bottom: 1px solid var(--flexi-brand_color_1);
    }
}

.primary-nav .wrapper {
    background: #2E9FDE;
    background: var(--flexi-secondary_color_2);
}

.burgerbar .burgerbar-small {
    width: 100%;
    max-height: 60px;
    height: 60px;
}

.menu-item {
    background-color: #2E9FDE;
    background-color: var(--flexi-secondary_color_2);
}

@media only screen and (min-width: 700px) {
    .burgerbar .burgerbar-small {
        position: relative;
    }
}

@media screen and (min-width: 1574px) {
    body.limit-width .container.full {
        max-width: 970px;
    }
}

@media (max-width: 699px) {
    .burgerbar .logo {
        margin-left: 60px;
    }
}

#burgerbar, #burgerbar ~ .burgerbar .button-menu.checked {
    display: none;
}

@media only screen and (max-width: 699px) {
    #burgerbar:checked ~ .burgerbar .button-menu.unchecked {
        display: none;
    }
}

@media only screen and (max-width: 699px) {
    #burgerbar:checked ~ .burgerbar .button-menu.checked, #burgerbar:checked ~ .wrapper .menuitems {
        display: block;
        padding-left: 0;
        padding-right: 0;
    }
}

.menu-hamburger {
    height: 60px;
    margin-bottom: 0;
}

.menu-hamburger:before, .menu-hamburger:after {
    content: "";
    position: absolute;
    width: 18px;
    margin-left: -9px;
    top: 50%;
    left: 50%;
    box-sizing: content-box;
    box-sizing: initial;
}

.menu-hamburger:after {
    height: 2px;
    background: #fff;
    margin-top: -1px;
}

.menu-hamburger:before {
    height: 8px;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    margin-top: -6px;
}

.burgerbar .button-menu {
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: absolute;
    left: 0;
    width: 60px;
}

@media (max-width: 699px) {
    .primary-nav .wrapper .menuitems .nav-list {
        max-height: calc(100vh - 60px - 50px);
        overflow-y: scroll;
        padding-left: 0px;
    }
    .primary-nav .wrapper .menuitems .nav-list li {
        display: block !important;
        width: auto !important;
    }
    .primary-nav .wrapper .menuitems .nav-list li.menu-item-more {
        display: none !important;
    }
}

@media only screen and (min-width: 700px) {
    .burgerbar .burgerbar-small > .button-menu {
        display: none;
    }
}

@media only screen and (min-width: 700px) {
    .burgerbar .burgerbar-small > .button-menu {
        display: none;
    }
}

#burgerbar, #burgerbar ~ .burgerbar .button-menu.checked {
    display: none;
}

.burgerbar .logo {
    line-height: 0;
    height: 100%;
    text-align: left;
}

.burgerbar .logo {
    line-height: 0;
    height: 100%;
}

.burgerbar .logo {
    text-align: left;
}

@media only screen and (min-width: 700px) {
    .burgerbar .burgerbar-small .logo {
        padding: 0 16px;
        position: absolute;
    }
}

.burgerbar .logo > a {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.burgerbar .logo a {
    color: #fff;
}

.burgerbar .site-logo {
    position: relative;
}

.burgerbar .logo .site-logo {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    line-height: 0;
    vertical-align: middle;
    float: left;
}

.burgerbar .logo h1, .burgerbar .logo .hidden-header {
    display: none;
}

.burgerbar .logo .strapline {
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    float: left;
    line-height: 13px;
    margin-top: 27px;
    margin-left: 13px;
}

.burgerbar .logo .strapline, .burgerbar .button-search span {
    color: #ffffff;
}

.search-checkbox {
    display: none;
}

.button-search {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 60px;
    height: 60px;
    margin-bottom: 0;
    line-height: 60px;
}

@media only screen and (min-width: 700px) {
    .button-search {
        position: relative;
    }
}

.masthead-item {
    width: auto;
    text-align: center;
    font-size: 0;
}

@media only screen and (min-width: 700px) {
    .masthead-item {
        padding-right: 1rem;
        padding-left: 1rem;
        position: relative;
        height: 100%;
        float: right;
    }
}

.burgerbar .button-search {
    margin-left: 10px;
    min-width: 60px;
}

@media only screen and (min-width: 700px) {
    .masthead-item .desktop-text {
        display: inline-block;
        zoom: 1;
        *display: inline;
        padding-right: 8px;
        font-size: 14px;
        vertical-align: middle;
        line-height: 60px;
    }
}

.burgerbar .logo .strapline, .burgerbar .button-search span {
    color: #ffffff;
}

.masthead-item .icon-search {
    display: inline-block;
    zoom: 1;
    *display: inline;
    vertical-align: middle;
    font-size: 1.4rem;
    line-height: 1.4rem;
    height: 22px;
    width: 23px;
}

@media only screen and (min-width: 700px) {
    .masthead-item .icon-search {
        font-size: 1rem;
        line-height: 1rem;
    }
}

@media screen and (min-width: 575px) {
    .masthead-item .icon-search {
        height: 16px;
        width: 16px;
    }
}

.masthead-item .icon-search .icon-image {
    fill: #fff;
}

.search-box {
    z-index: 1000;
    position: absolute;
    right: 0;
    top: 60px;
    width: 100%;
    border: 2px solid #2E9FDE;
    border: 2px solid var(--flexi-secondary_color_2);
    box-sizing: border-box;
    background-color: #fff;
    padding: 1em 18px;
    font-size: 16px;
    display: none;
    line-height: 44px;
}

.burgerbar .burgerbar-small .search-box {
    width: 100%;
}

@media screen and (min-width: 575px) {
    .burgerbar .burgerbar-small .search-box {
        width: 480px;
    }
}

input[type="search"] {
    -webkit-appearance: none;
    border: 1px solid #ddd;
    font-size: 1em;
    padding-right: 3em;
    line-height: 2.5em;
    padding-left: .75em;
    width: 100%;
    outline-offset: 0;
    border-radius: 0;
    margin-top: 0;
    margin-bottom: 0;
}

@media screen and (min-width: 700px) {
    input[type="search"] {
        padding-right: 0;
    }
}

@media screen and (min-width: 900px) {
    input[type="search"] {
        padding-right: 54px;
        padding-top: 13px;
        padding-bottom: 11px;
        line-height: 16px;
        font-size: 16px;
    }
}

.search-box input {
    color: #333;
}

.flexi-page.responsive input[type="search"] {
    box-sizing: inherit;
}

.search-box .search-submit {
    position: absolute;
    right: 18px;
    top: 16px;
    width: 44px;
    height: 44px;
    background-color: #333;
    color: #fff;
    border: none;
    padding: 0;
}

.flexi-page.responsive .search-box .search-submit {
    top: 18px;
}

.flexi-page.responsive .search-box .search-submit {
    top: 18px;
}

.search-box .search-submit .icon-search {
    display: flex;
    flex-direction: column;
}

svg:not(:root) {
    overflow: hidden;
}

.search-box .search-submit .icon-search .icon-image {
    height: 20px;
    fill: #fff;
}

.masthead-item {
    width: auto;
    text-align: center;
    font-size: 0;
}

@media only screen and (min-width: 700px) {
    .masthead-item {
        padding-right: 1rem;
        padding-left: 1rem;
        position: relative;
        height: 100%;
        float: right;
    }
}

.masthead-item {
    width: auto;
    text-align: center;
    font-size: 0;
}

@media only screen and (min-width: 700px) {
    .masthead-item {
        padding-right: 1rem;
        padding-left: 1rem;
        position: relative;
        height: 100%;
        float: right;
    }
}

.burgerbar .buttons-social {
    display: none;
    min-height: 60px;
    padding: 0;
}

@media only screen and (min-width: 700px) {
    .burgerbar .buttons-social {
        display: block;
    }
}

.masthead-item.buttons-social {
    padding: 0;
}

.icon-circle {
    border-radius: 50%;
    display: block;
}

.buttons-social .facebook, .buttons-social .icon-facebook, .socialite-widget-item .facebook, .socialite-widget-item .icon-facebook {
    background: #3B5998;
}

.buttons-social .icon-twitter, .buttons-social .twitter, .socialite-widget-item .icon-twitter, .socialite-widget-item .twitter {
    background: #4099FF;
}

.buttons-social .icon-instagram, .buttons-social .instagram, .socialite-widget-item .icon-instagram, .socialite-widget-item .instagram {
    background: #de3073;
}

.buttons-social .icon-youtube, .buttons-social .youtube, .socialite-widget-item .icon-youtube, .socialite-widget-item .youtube {
    background: #CD201F;
}

.buttons-social .icon-rss {
    background-color: darkorange;
}

.burgerbar .buttons-social a {
    border: none;
}

@media only screen and (min-width: 700px) {
    .burgerbar .buttons-social a {
        float: left;
        border: 1px solid #fff;
        width: 24px;
        height: 24px;
        margin-top: 18px;
        box-sizing: border-box;
        color: #fff;
        margin-right: 10px;
    }
}

.burgerbar .buttons-social a {
    border: none;
}

.burgerbar .buttons-social .icon-circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.burgerbar .buttons-social .icon-image {
    display: block;
    fill: #fff;
    height: 14px;
    width: 24px;
}

.burgerbar .buttons-social .icon-rss .icon-image {
    width: 20px;
    height: 12px;
    padding-left: 4px;
}

.primary-nav .menuitems {
    display: none;
    position: absolute;
    width: 100%;
    left: 0;
    top: 60px;
}

@media screen and (min-width: 700px) {
    .primary-nav .menuitems {
        z-index: auto;
        display: block;
        position: relative;
        top: 0;
        max-height: 44px;
    }
}

@media screen and (min-width: 1574px) {
    body.limit-width .container.full {
        max-width: 970px;
    }
}

@media screen and (min-width: 1574px) {
    body.limit-width .container.full {
        max-width: 970px;
    }
}

@media screen and (min-width: 700px) {
    .nav-list {
        height: 44px;
        overflow: hidden;
    }
}

.nav-list {
    font-size: 0;
}

.nav-list.component-loaded {
    height: auto;
    overflow: visible;
    padding-left:0;
}

.menu-item > span {
    outline: none !important;
}

@media screen and (min-width: 700px) {
    .menu-item {
        display: inline-block;
        zoom: 1;
        *display: inline;
        vertical-align: top;
        font-size: 0;
        background-color: transparent;
    }
}

.nav-list .menu-item, .nav-list .menu-item .sub-menu > .menu-item {
    visibility: visible;
}

.menu-item-hover-reveal {
    line-height: 44px;
    color: #fff;
    padding-left: 1rem;
    padding-right: 1rem;
    white-space: nowrap;
    cursor: default;
}

.menu-item-hover-reveal.menu-item, .menu-item-magazines.menu-item {
    font-size: 1rem;
}

@media screen and (min-width: 700px) {
    .menu-item-hover-reveal:hover .sub-menu, .menu-item-hover-reveal:focus .sub-menu {
        display: block;
        position: absolute;
        margin-left: -16px;
    }
}

.sub-menu {
    display: none;
    padding: 0;
    vertical-align: top;
    background-color: #2E9FDE;
    background-color: var(--flexi-secondary_color_2);
    font-size: 0;
}

.sub-menu .menu-item {
    display: block;
}
@media screen and (min-width: 700px) {
    .menu-item-more {
        /*display: none;*/
    }
}

.menu-item-more svg {
    fill: #ffffff;
    width: 15px;
    padding-left: 5px;
}
@media screen and (min-width: 700px) {
    .menu-item:nth-child(n+4):not(.menu-item-nav-sub-set), .menu-item.menu-item-hover-reveal, .menu-item.menu-item-more, .menu-item.more-hide {
        visibility: hidden;
    }
}

.menu-item.menu-item-home {
    min-width: 53px;
}

.menu-item.menu-item-home i {
    width: 21px;
    height: 21px;
}

.nav-list .menu-item {
    visibility: visible;
}

.menu-item.menu-item-home {
    min-width: 53px;
    margin-top: -2px;
}

.menu-item.menu-item-home svg {
    fill: #fff;
    width: 21px;
}

@media only screen and (max-width: 699px) {
    .menu-item.menu-item-home svg {
        width: 21px;
        height: 21px;
    }
}

.menu-item a {
    display: block;
    line-height: 44px;
    font-size: 1rem;
    padding-left: 1rem;
}

@media screen and (min-width: 700px) {
    .menu-item a {
        display: inline-block;
        zoom: 1;
        *display: inline;
        vertical-align: top;
    }
}

.menu-item a, .menu-item a:visited, .menu-item a:hover, .menu-item a:visited:hover {
    color: #fff;
    display: block;
}

.menu-item .mag-menu-reveal:not(.maglink), .menu-item .menu-item-magazines:not(.maglink), .menu-item a:not(.maglink) {
    padding-right: 1rem;
}

.menu-item a:not(.maglink) {
    padding-right: 1rem;
}

.search-box.open {
    display: block;
}

#document-footer {
    background-color: #666;
    max-width: 100%;
    margin-bottom: 0;
    margin-top: 0;
    width: 100%;
    position: relative;
    margin-top: 10px;
}

#document-footer-content {
    color: #fff;
    font-size: 14px;
    padding: 28px 1.5em;
    max-width: 928px;
    margin: 0 auto;
}

#document-footer-content p {
    line-height: 24px;
}

#document-footer-content p:first-child {
    margin-bottom: 1.5em;
}

#document-footer-content nav {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    margin-bottom: 1.5em;
    width: 100%;
}

@media (min-width: 700px) {
    #document-footer-content nav {
        display: inline-block;
        zoom: 1;
        *display: inline;
    }
}

@media (min-width: 700px) {
    #document-footer-content nav ul {
        display: grid;
        grid-template-columns: auto auto auto auto;
    }
}

#document-footer-content nav ul {
    margin: 8px 0;
    list-style-type: none;
}

@media (min-width: 700px) {
    #document-footer-content nav li {
        display: inline-block;
        zoom: 1;
        *display: inline;
        margin-right: 20px;
    }
}

#document-footer-content nav a {
    color: #fff;
    line-height: 44px;
    position: relative;
    text-align: center;
    display: block;
}

#document-footer-content p a {
    color: #fff;
    font-weight: 700;
}

@media (min-width: 700px) {
    #document-footer-content nav a {
        text-align: left;
    }
}

@media (min-width: 700px) {
    #document-footer-content nav a {
        line-height: 30px;
    }
}


.container-fluid.full {
    max-width:970px;
    font-family: 'Open Sans', sans-serif;
}

.trending-wrapper {
    margin: 30px 11px;
}
.trending-wrapper .trending-bar {
    *zoom: 1;
    display: flex;
    background-color: transparent;
    position: relative;
    clear: both;
    white-space: nowrap;
    width: 100%;
    height: 40px;
    font-size: 14px;
    background: #ededed;
    overflow: hidden;
    justify-content: center;
}
.trending-wrapper .trending-items {
    margin:0;
    padding:0;
    overflow: auto;
    overflow-y: auto;
    overflow-y: hidden;
    height: 100%;
    display: inline-block;
    text-align: center;
}
.trending-wrapper .trending-item {
    display: inline-block;
    *display: inline;
    margin-left: -2px;
    line-height: 40px;
}
.trending-wrapper .trending-item a {
    line-height: 40px;
    padding: 0 14px;
    display: block;
    color:#333;
    font-weight:600;
}
.trending-wrapper .trending-items::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgb(0 0 0 / 30%);
    background-color: #F5F5F5;
    border-radius: 10px;
}
.trending-items::-webkit-scrollbar-thumb {
    background-color: #2E9FDE;
    border-radius: 10px;
}
.trending-wrapper .trending-items::-webkit-scrollbar {
    height: 6px;
    background-color: #F5F5F5;
}

.nav-list {
    margin-bottom:0;
}

